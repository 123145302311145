import React, { forwardRef } from 'react';
import {
  BarbacoaBeefTaco,
  BarbacoaBeefTortilla,
  MoleChickenTaco,
  MoleChickenSliders,
  PorkSalsaTaco,
  PorkSalsaTostada,
  BeefPlate,
  MolePlate,
  PorkPlate,
} from '../assets/images';

const recipesList = [
  {
    name: 'Barbacoa Beef Taco',
    image: BarbacoaBeefTaco,
    id: 'Barbacoa-Beef-Taco',
  },
  {
    name: 'Barbacoa Beef Tortilla Flautas',
    image: BarbacoaBeefTortilla,
    id: 'Barbacoa-Beef-Tortilla-Flautas',
  },
  {
    name: 'Mole Chicken Taco',
    image: MoleChickenTaco,
    id: 'Mole-Chicken-Taco',
  },
  {
    name: 'Mole Chicken Sliders',
    image: MoleChickenSliders,
    id: 'Mole-Chicken-Sliders',
  },
  {
    name: 'Pork in Green Salsa Taco',
    image: PorkSalsaTaco,
    id: 'Pork-in-Green-Salsa-Taco',
  },
  {
    name: 'Pork in Green Salsa on a Tostada',
    image: PorkSalsaTostada,
    id: 'Pork-in-Green–Salsa-on-a-Tostada',
  },
];

const Recipes = forwardRef((props, ref) => {

  const scrollToRecipe = (scrollTo) => {
    const titleElement =  document.getElementById(scrollTo);
    titleElement.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <section ref={ref} className='bg-mf-cream-100'>
      <div className='relative flex min-h-[25vh] items-center bg-gradient-to-b from-mf-orange-300 via-mf-orange-400 to-mf-orange-300 sm:mb-16 md:mb-0 lg:justify-between xl:min-h-[50vh] 2xl:h-[65vh]'>
        <div className='absolute left-0 hidden w-1/4 sm:-top-8 sm:flex md:-top-16 lg:w-1/5 xl:-top-20 xl:w-1/4 2xl:static'>
          <img src={BeefPlate} alt='' className='lg:w-full' id='beef-img-banner-recipes' />
        </div>
        <h2 className='mx-auto font-montserrat text-5xl font-extrabold uppercase text-white drop-shadow-[0_3px_0_#633616] md:text-8xl'>
          Recipes
        </h2>
        <div className='absolute right-0 hidden sm:-top-20 sm:flex sm:w-1/4 sm:flex-col md:bottom-0 md:w-1/5 lg:w-1/6 xl:w-1/5 2xl:static'>
          <img src={PorkPlate} alt='' />
          <img src={MolePlate} alt='' />
        </div>
      </div>
      <div>
        <div className='grid grid-cols-1 gap-8 p-8 md:grid-cols-2 md:gap-24 md:p-24 lg:grid-cols-3 mt-8'>
          {recipesList.map((recipe) => (
            <div
              className='col-span-1 inline-flex flex-col items-center cursor-pointer'
              key={recipe.name}
              onClick={ (id) => scrollToRecipe(recipe.id) }
            >
              <img
                src={recipe.image}
                alt={recipe.name}
                className='object-cover'
              />
              <div className='h-8 w-2/5 bg-brownLine bg-contain bg-center bg-no-repeat'></div>
              <h3 className='px-12 text-center font-gothamBold text-xl uppercase text-brown-cafe-custom-100 md:text-4xl lg:text-2xl'>
                {recipe.name}
              </h3>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
});

export default Recipes;
