import React, { useState, useRef, forwardRef } from "react";
import { CustomerCare } from "../assets/images/";

const Contact = forwardRef((props, ref) => {

  const [ error, setError ] = useState(false);
  const [ errorIp, setErrorIp ] = useState(false);
  const [ emailValidate, setEmailValidate ] = useState(false);
  const [ phoneValidate, setPhoneValidate ] = useState(false);
  const [ formSuccess, setFormSuccess ] = useState(false);
  const [ name, setName ] = useState(false);
  const contactForm = useRef(null);
  const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const phoneRegex = /^[0-9\b]+$/;
  
  const sendContactForm = (e) => {
    e.preventDefault();

    setError(false);
    setEmailValidate(false);
    setPhoneValidate(false);

    const form = contactForm.current;

    var contactFormData = {
      name: form['name'].value,
      last_name: form['last_name'].value,
      email: form['email'].value,
      phone: form['phone'].value,
      address: form['address'].value,
      city: form['city'].value,
      country: form['country'].value,
      reason: form['reason'].value,
      comments: form['comments'].value,
      privacy_read: form['privacy'].checked,
    }

    for(let x in contactFormData)
    {
      if(!contactFormData[x])
      {
        setError(true);
        return;
      }
    }
    if(!regexEmail.test(contactFormData.email)){
      setEmailValidate(true);
      return;
    }
    if(!phoneRegex.test(contactFormData.phone)){
      setPhoneValidate(true);
      return;
    }

    contactFormData.acepts_ads = form['ads'].checked;

    const options = {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
      },
      body: JSON.stringify(contactFormData),
    }
    const backend_uri = 'https://backend.isadorabeans.com/api/mexicanfoods-contacts';

    fetch(backend_uri, options)
      .then((response) => response.json())
      .then(
        (result) => {
          if(result.Status=="Validation failed")
          {
            setError(true);
          }
          if(result.Status=="Contact Not Saved, IP already used")
          {
            setName(contactFormData.name);
            setErrorIp(true);
          }
          if(result.Status=="Not a valid email provider")
          {
            setEmailValidate(true);
          }
          if(result.Status=="Success")
          {
            setFormSuccess(true);
          }
        },
        (error) => {
          console.log(error);
          setFormSuccess(true);
        });
  }

  return (
    <section className="bg-mf-cream-100 pt-20 pb-14" ref={ref} id="contact-section-bookmark">
      <div className="container mx-auto max-w-screen-xl px-5">
        <div className="mx-auto justify-center text-center align-middle lg:w-4/5">
          <h2 className="font-futuraBold text-4xl md:text-5xl font-extrabold uppercase text-mf-orange-200 tracking-wider">
            Congrats or comments
          </h2>
          <div className="mx-auto h-16 w-48 bg-brownLine bg-contain bg-center bg-no-repeat"></div>
          <p className="mx-auto font-alternate3 text-3xl text-mf-brown-100 md:max-w-[80%] lg:max-w-[80%]">
            Do you have any questions, comments or would you like to know more
            about our authentic line of{" "}
            <span className="font-gothamBold uppercase">MEXICAN CUISINE</span>{" "}
            Isadora<small className="small-trademark font-futuraBold">&reg;</small> products?<br></br>
            Contact us and we will assist you as soon as possible.
          </p>
          <a
            href="tel:8006346130"
            className="mx-auto mt-16 mb-10 block max-w-[80%] md:max-w-[450px]"
          >
            <img
              src={CustomerCare}
              alt="Customer Care telephone: 800-646-6130"
              className="h-auto w-auto"
            />
          </a>

          
        </div>
      </div>
    </section>
  );
});

export default Contact;
