import React from 'react';

const Store = ({ store, productName }) => {
  const { name, logo, links } = store;
  let link = '';

  if (productName === 'Mole') {
    link = links.mole;
  }

  if (productName === 'Barbacoa') {
    link = links.barbacoa;
  }

  if (productName === 'Pork') {
    link = links.pork;
  }

  // TODO: implementar lógica de "tienda disabled" según stock
  return (
    <a
      className="flex flex-col items-center justify-between"
      href={link}
      target="_blank"
      rel="noreferrer"
    >
      <img
        src={logo}
        alt={name}
        className="m-auto w-[60%] smallDesktop:w-[35%]"
      />
      <div>
        <button className="font-montserrat text-sm font-bold uppercase lg:text-base">
          Buy Now
        </button>
        <div className="min-[900px]:w-32 mx-auto h-10 w-24 bg-brownLine bg-contain bg-center bg-no-repeat"></div>
      </div>
    </a>
  );
};

export default Store;
