import {
  BarbacoaBeefTacoRecipe,
  BarbacoaBeefTortillaFlautas,
  MoleChickenTacoRecipe,
  MoleChickenSlidersRecipe,
  PorkInGreenSalsaTaco,
  PorkInGreenSalsaTostada,
} from '../../assets/images';

const data = [
  {
    title: 'Barbacoa',
    color: 'text-recipes-colors-orange',
    bgColor: 'bg-recipes-colors-orange',
    recipes: [
      {
        title: 'BARBACOA <br>BEEF TACO',
        alt: 'Barbacoa Beef Taco',
        img: BarbacoaBeefTacoRecipe,
        flag: '*Tips for accompanying barbacoa:',
        id: 'Barbacoa-Beef-Taco',
        recipe: [
          {
            ingredients: [
              [
                '1 pouch of Barbacoa mexican<br class="lg:block hidden"> style seasoned beef <span class="font-bold">Isadora®</span>',
                '8 corn tortillas',
              ],
              [
                '2 limes',
                '2 tablespoons of fresh, chopped cilantro',
                '2 tablespoons of chopped onion',
              ],
            ],
            directions: [
              [
                'Warm the tortillas on a comal or on a griddle until soft.',
                'Heat the barbacoa beef in the microwave or in a pan and set aside.',
                'Spoon the barbacoa beef into a tortilla, then top with onion and a bit of chopped cilantro.',
                'Serve with lime wedges if desired.',
              ],
            ],
          },
          {
            ingredients: [
              [
                '3 pasilla peppers',
                '1 arbol pepper',
                '1 garlic clove',
                '¼ onion',
              ],
              [
                '¼ cup of beer or pulque',
                '2 tomatillos',
                '1 red tomato',
                'Salt to taste',
              ],
            ],
            directions: [
              [
                'Sautee the peppers, garlic, onion, tomatillos and red tomato in a hot comal, griddle or pan.',
                'Blend all ingredients in a blender at high speed until the consistency is thin. Then mix in the beer or pulque and salt to taste.',
              ],
            ],
          },
        ],
      },
      {
        title: 'BARBACOA BEEF <br>TORTILLA FLAUTAS',
        alt: 'Barbacoa Beef Tortilla Flautas',
        img: BarbacoaBeefTortillaFlautas,
        id: 'Barbacoa-Beef-Tortilla-Flautas',
        recipe: [
          {
            ingredients: [
              [
                '12 large tortillas',
                '1 pouch of Barbacoa mexican style<br class="lg:block hidden"> seasoned beef <span class="font-bold">Isadora®</span>',
                '1 cup of chopped lettuce',
              ],
              [
                '1 chopped tomato',
                '½ cup of sour cream',
                '½ cup of queso fresco',
              ],
            ],
            directions: [
              [
                'Fill the tortillas with the barbacoa and roll tightly to ensure they don’t absorb oil when frying. <br>Use a toothpick to hold in place and set aside.',
                'Heat oil in a pan on high heat and fry the rolled flautas.',
                'Place the flautas on a paper towel and set aside.',
                'Serve the flautas with chopped lettuce, cream cheese, chopped tomato and crumbled queso fresco.',
              ],
            ],
          },
        ],
      },
    ],
  },
  {
    title: 'Mole',
    color: 'text-recipes-colors-purple',
    bgColor: 'bg-recipes-colors-purple',
    recipes: [
      {
        title: 'MOLE<br> CHICKEN TACO',
        alt: 'Mole Chicken Taco',
        img: MoleChickenTacoRecipe,
        id: 'Mole-Chicken-Taco',
        recipe: [
          {
            ingredients: [
              [
                '1 pouch of Chicken with Mole <span class="font-bold">Isadora®</span>',
                '8 corn tortillas',
                'Toasted sesame seeds to taste',
                '½ sliced onion',
                '2 limes',
              ],
            ],
            directions: [
              [
                'Warm the tortillas on a comal or on a griddle until soft.',
                'Heat the chicken with mole in the microwave or in a pan.*',
                'Spread the chicken with mole on the tortillas and add a bit of toasted sesame seed.',
                'Serve with slices of lime.',
              ],
            ],
          },
        ],
      },
      {
        title: 'MOLE <br>CHICKEN SLIDERS',
        alt: 'Mole Chicken Sliders',
        img: MoleChickenSlidersRecipe,
        flag: '*Tips for accompanying barbacoa:',
        id: 'Mole-Chicken-Sliders',
        recipe: [
          {
            ingredients: [
              [
                '1 pouch of Chicken with Mole <span class="font-bold">Isadora®</span>',
                '8 small buns',
                '2 sliced limes',
                '¼ white onion',
              ],
            ],
            directions: [
              [
                'Slice the buns in half and heat both sides in a pan<br class="xl:block hidden"> with butter or oil. ',
                'Heat the chicken with mole in the microwave or in a pan.',
                'Spread two tablespoons of the chicken mixture on one<br class="xl:block hidden"> side of the bun, then cover with the other half.',
                'Serve with sliced onion if desired.',
              ],
            ],
          },
          {
            ingredients: [
              [
                '1 cup of chopped cabbage',
                '¼ cup of sliced onion',
                '¼ cup of chopped carrot',
                '1 sliced serrano pepper',
              ],
              [
                '2 tablespoons of mayonnaise',
                '1 grated garlic',
                '1 lemon',
                'Salt',
              ],
            ],
            directions: [
              [
                'Mix the mayonnaise, lemon juice, salt and<br class="xl:block hidden"> grated garlic in a bowl.',
                'Mix the mayonnaise preparation with the<br class="xl:block hidden"> cabbage, serrano pepper, carrot and onion,<br class="xl:block hidden"> then salt to taste.',
              ],
            ],
          },
        ],
      },
    ],
  },
  {
    title: 'Pork',
    color: 'text-recipes-colors-green',
    bgColor: 'bg-recipes-colors-green',
    recipes: [
      {
        title: 'PORK IN GREEN <br>SALSA TACO',
        alt: 'Pork in Green Salsa Taco',
        img: PorkInGreenSalsaTaco,
        flag: '*Tips for accompanying barbacoa:',
        id: 'Pork-in-Green-Salsa-Taco',
        recipe: [
          {
            ingredients: [
              [
                '3 chopped radishes',
                '2 slices of pineapple',
                '1 lemon',
                'Salt',
                'Vegetable or olive oil',
              ],
            ],
            directions: [
              [
                'Heat the pineapple slices in a pan on both sides until golden brown, then cube.',
                'Mix the pineapple and chopped radish, then add the lemon juice, salt and olive oil.',
                'Serve as a side dish.',
              ],
            ],
          },
          {
            ingredients: [
              [
                '1 pouch of Pork in green salsa <span class="font-bold">Isadora®</span>',
                '2 tablespoons of fresh, chopped cilantro',
                '8 corn tortillas',
                '2 limes',
              ],
            ],
            directions: [
              [
                'Warm tortillas on a comal or on a griddle until soft. Store in a napkin untill served.',
                'Heat the pork in green salsa either in the microwave or in a pan.* Once warm, served in a dish for sharing.',
                'Put two tablespoons of the pork in green salsa in each tortilla. Garnish with a bit of fresh, chopped cilantro.',
                'Serve with lime wedges.',
              ],
            ],
          },
        ],
      },
      {
        title: 'PORK IN GREEN SALSA<br> ON A TOSTADA',
        alt: 'Pork in Green Salsa on a Tostada',
        img: PorkInGreenSalsaTostada,
        id: 'Pork-in-Green–Salsa-on-a-Tostada',
        recipe: [
          {
            ingredients: [
              [
                '1 pouch of Pork in green salsa <span class="font-bold">Isadora®</span>',
                '1 cup of refried beans',
                '8 tostadas',
                '2 tablespoons of fresh, chopped cilantro',
              ],
            ],
            directions: [
              [
                'Heat the pouch of pork in green salsa in the microwave or in a frying pan.',
                'Heat refried beans, taking care to stir often so they maintain their creamy texture.',
                'Spread tostadas generously with refried beans, then apply on top pork in green salsa.',
                'Garnish with fresh, chopped cilantro.',
              ],
            ],
          },
        ],
      },
    ],
  },
];

export default data;
