import React, { useState } from 'react';
import useWindowDimensions from '../useWindowDimensions';
import { customHr } from '../../assets/images';
import useModalStore from '../StoreModal/useModalStore';

const SingleProduct = ({ prod }) => {
  const [hover, setHover] = useState(false);
  const [ingredients, setIngredients] = useState(true);
  const { height, width } = useWindowDimensions();

  const scrollToRecipe = (prefix) => {
    const titleElement = document.getElementById(prefix + 'RecipeSectionTitle');
    titleElement.scrollIntoView({ behavior: 'smooth' });
  };

  const updateModalState = useModalStore((state) => state.updateModalState);
  const updateStoreList = useModalStore((state) => state.updateStoreList);

  const handleOpen = (productName) => {
    updateStoreList(productName);
    updateModalState(true);
  };

  /* const scrollToWhereToBuy = () => {
    const titleElement = document.getElementById('whereToBuyScrollSection');
    titleElement.scrollIntoView({ behavior: 'smooth' });
  }; */

  return (
    <div className="mobile-padding-x-0 flex flex-col px-12 py-8 md:flex-row 1.5xl:p-20">
      <div className="mx-auto flex w-3/4 flex-col justify-center p-0 1.5xl:w-[60%]">
        <img
          src={hover ? prod.img_reverse : prod.img}
          alt={`${prod.title} package`}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        />
        <p className="hover-over-instruction mt-5 text-center font-gothamBold text-sm font-semibold text-[#292626]">
          {width < 578
            ? 'Click over the image to see the reverse.'
            : 'Hover over the image to see the reverse.'}
        </p>
      </div>
      <div className="mt-16 flex w-full flex-col justify-between py-0 px-8 pt-16">
        <div className="-mt-6 mb-16 flex justify-center">
          <img
            src={prod.img_made_with}
            alt="made with logo"
            className="w-[75%] sm:w-1/2 lg:w-1/4"
          />
        </div>
        <div className="flex justify-center">
          <h3
            className="m-4 font-gothamBold text-2xl uppercase leading-[0.9] text-mf-brown-200 md:text-4xl"
            dangerouslySetInnerHTML={{ __html: prod.formated_title }}
            id={prod.recipe_prefix + 'ProductTitle'}
          ></h3>
        </div>
        <div className="px-4rem mobile-0-padding-x">
          <p
            className="text-justify font-gothamBook font-semibold text-mf-brown-200"
            dangerouslySetInnerHTML={{ __html: prod.description }}
          />
        </div>
        <div className="my-8 flex flex-row justify-center pt-4 xl:pt-1">
          <button
            className={`m-0 p-4 font-gothamBold text-xl font-semibold uppercase tracking-[-1px] text-mf-brown-200 ${
              ingredients ? 'bg-[#efa14e]' : 'bg-transparent'
            }`}
            onClick={() => setIngredients(true)}
          >
            Ingredients
          </button>
          <button
            className={`m-0 p-4 font-gothamBold text-xl font-semibold uppercase tracking-[-1px] text-mf-brown-200 ${
              ingredients ? 'bg-transparent' : 'bg-[#efa14e]'
            }`}
            onClick={() => setIngredients(false)}
          >
            Nutrition facts
          </button>
        </div>
        <div className="px-4rem mobile-0-padding-x">
          {ingredients ? (
            <p
              className="brown-custom text-justify font-gothamUltra font-bold text-mf-brown-200 xl:pt-4 xl:pb-16"
              dangerouslySetInnerHTML={{ __html: prod.ingredients }}
            ></p>
          ) : (
            <img
              src={prod.nutri_img}
              className="m-auto w-3/4 py-5 sm:w-1/2"
              alt="nutritional info"
            />
          )}
        </div>
        <div className="flex justify-center pt-4 pb-4">
          <img src={customHr} className="m-auto w-1/4" alt="" />
        </div>
        <div className="flex justify-center">
          <div className="flex flex-col justify-center">
            <button
              className="m-2 bg-[#efa14e] p-4 font-gothamBold text-xl font-semibold uppercase tracking-[-1px] text-mf-brown-200"
              onClick={() => scrollToRecipe(prod.recipe_prefix)}
            >
              Lets cook
            </button>
            <button
              className="m-2 bg-[#efa14e] p-4 font-gothamBold text-xl font-semibold uppercase tracking-[-1px] text-mf-brown-200"
              onClick={() => handleOpen(prod.recipe_prefix)}
            >
              Buy now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleProduct;
