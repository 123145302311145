import React from 'react';
import { ProductPouches } from '../assets/images';

const Hero = () => {
  return (
    <section className="relative overflow-x-hidden bg-gradient-to-b from-mf-orange-300 via-mf-orange-400">
      <div className="container relative z-20 mx-auto max-w-screen-xl px-5 md:pt-16">
        <div className="flex flex-col justify-center">
          <div className="mx-auto my-10 min-h-[150px] w-full bg-heroTitle bg-contain bg-top bg-no-repeat md:my-6 md:min-h-[230px] lg:max-w-[600px]">
            <h2 className="sr-only">Authentic Cuisine Direct from México</h2>
          </div>
          <div className="mx-auto">
            <img
              src={ProductPouches}
              alt="Isadora Porduct Pouches"
              className="mx-auto max-w-[90%] md:max-w-[80%]"
            />
          </div>
        </div>
      </div>
      <div
        id="whiteBlock"
        className="absolute bottom-0 -mt-[100px] min-h-[100px] w-[110%] translate-x-[-5%] rounded-t-hero-sm bg-white md:-mt-[200px] md:min-h-[200px] md:rounded-t-hero-lg lg:-mt-[300px] lg:min-h-[300px]"
      ></div>
    </section>
  );
};

export default Hero;
