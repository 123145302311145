import { Fragment, forwardRef } from 'react';
import SingleProduct from './SingleProduct';

import {
  productBarbacoBeef,
  productPolloMole,
  productPork,
  madeWithChicken,
  madeWithBeef,
  madeWithPork,
  beefBack,
  chickenBack,
  porkBack,
  nutrition_facts_Beef,
  nutrition_facts_chicken,
  nutrition_facts_pork,
} from '../assets/images';
import React from 'react';

const productsList = [
  {
    title: 'barbacoa mexican style seasoned beef',
    formated_title:
      "<strong class='font-gothamUltra font-bold'>BARBACOA</strong><br />MEXICAN STYLE<br />SEASONED BEEF",
    description: `Barbacoa is a dish originating from the mexican from the Mexican Tlaxcalteca culture. <strong class='font-gothamUltra font-bold'> Traditionally, it was prepared by wrapping the meat with roasted maguey leaves and slow cooking in an earth oven.</strong>`,
    ingredients: `Beef, water, tomato puree, vinegar, salt, onion powder, contains 1% or less of garlic powder, oregano, ground cumin, natural flavouring and paprika.`,
    img: productBarbacoBeef,
    img_reverse: beefBack,
    nutri_img: nutrition_facts_Beef,
    img_made_with: madeWithBeef,
    recipe_prefix: 'Barbacoa',
  },
  {
    title: 'pollo con mole chicken with mole sauce',
    formated_title:
      "<strong class='font-gothamUltra font-bold'>pollo <small>con</small> mole</strong><br />chicken <small>with</small><br />mole sauce",
    description: `The word “Mole” comes from the Nahuatl Language and translates as “stew” or “sauce”. <strong class="font-gothamUltra font-bold">It is prepared with a variety of chili peppers, aromatic spices, oilseeds, nuts, traditional Mexican chocolate, as well as other ingredients.</strong> This incredible chicken dish has been inspired over three centuries and is a Mexican favorite!`,
    ingredients: `Water, chicken meat, mole powder (corn flour, mulato pepper, ancho pepper, pasilla pepper, peanut, onion powder, garlic powder, cinnamon, cloves, ground black pepper, ground anise seed), brown sugar, chocolate (cocoa, sugar, cinnamon, soy lecithin), sesame seed and salt. <br /><br />CONTAINS PEANUTS, SOY, SESAME SEED.`,
    img: productPolloMole,
    img_reverse: chickenBack,
    nutri_img: nutrition_facts_chicken,
    img_made_with: madeWithChicken,
    recipe_prefix: 'Mole',
  },
  {
    title: 'chile verde pork in green salsa',
    formated_title:
      "<strong class='font-gothamUltra'>chile verde</strong><br />pork in<br />green salsa",
    description: `Inspired by the colors of the countryside and the cultural influences from different regions of México, <strong class="font-gothamUltra font-bold">our tender pork meat is cooked in green salsa prepared from tomatillos, serrano pepper, coriander and other seasonings.</strong> It is undoubtedly a popular dish worthy of our Mexican heritage.`,
    ingredients: `Pork, tomatillo, water, lard, onion, salt, serrano pepper, contains 1% or less of dehydrated coriander, garlic powder, sugar and natural flavorings.`,
    img: productPork,
    img_reverse: porkBack,
    nutri_img: nutrition_facts_pork,
    img_made_with: madeWithPork,
    recipe_prefix: 'Pork',
  },
];

const prodElements = [];

productsList.map((prod, index) =>
  prodElements.push(
    <Fragment key={index}>
      <SingleProduct prod={prod} loop={index} />
      {index < 2 && (
        <hr className="m-auto mt-16 h-[5px] w-1/3 bg-mf-brown-200 text-mf-brown-200" />
      )}
    </Fragment>
  )
);

const Products = forwardRef((props, ref) => {
  return (
    <section
      className="bg-recipesTexture bg-contain bg-center bg-repeat"
      ref={ref}
    >
      {prodElements}
    </section>
  );
});

export default Products;
