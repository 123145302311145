import PropTypes from 'prop-types';
import styles from './styles';
import { RecipeSubTitle, RecipeList } from './';

const RecipeMoleChickenSliders = ({ data }) => {
  return (
    <article className={`${styles.article}`}>
      <div className='text-center'>
        <RecipeSubTitle headline={data.recipes[1].title} id={data.recipes[1].id} m='lg:mb-[5rem]' />
        <img
          src={data.recipes[1].img}
          alt={data.recipes[1].alt + ' recipe'}
          className={`${styles.moleChickenSliderImg()}`}
        />
      </div>
      <RecipeList
        title='Ingredients'
        color={data.color}
        list={data.recipes[1].recipe[0].ingredients}
      />
      <div className='justify-between lg:flex lg:gap-x-6'>
        <RecipeList
          title='Directions'
          color={data.color}
          list={data.recipes[1].recipe[0].directions}
          basis='lg:basis-[33%] '
          borderRight='after:right-[-12px] after:xl:right-4'
        />
        <div className='relative gap-x-2 lg:flex lg:basis-[66%]'>
          {data.recipes[1].flag && (
            <span className={styles.flag(data.bgColor, 'left-0')}>
              {data.recipes[1].flag}
            </span>
          )}
          {['Ingredients', 'Directions'].map((title, i) => (
            <RecipeList
              title={title}
              color={data.color}
              list={data.recipes[1].recipe[1][title.toLowerCase()]}
              basis={`lg:basis-[50%] xl:basis-[${!i ? '4' : '5'}5%] `}
              key={i}
            />
          ))}
        </div>
      </div>
    </article>
  );
};

RecipeMoleChickenSliders.propTypes = {
  data: PropTypes.object.isRequired,
};

export default RecipeMoleChickenSliders;
