import React, { forwardRef } from 'react';
import {
  productBarbacoBeef,
  productPolloMole,
  productPork,
} from '../assets/images';
import useModalStore from './StoreModal/useModalStore';

const products = {
  mole: {
    name: 'Mole',
    logo: productPolloMole,
  },
  barbacoa: {
    name: 'Barbacoa',
    logo: productBarbacoBeef,
  },
  pork: {
    name: 'Pork',
    logo: productPork,
  },
};

const WhereToBuy = forwardRef((props, ref) => {
  const updateModalState = useModalStore((state) => state.updateModalState);
  const updateStoreList = useModalStore((state) => state.updateStoreList);

  const handleOpen = (productName) => {
    updateStoreList(productName);
    updateModalState(true);
  };

  const productsList = Object.values(products).map((product) => (
    <div
      className="flex w-1/2 flex-col items-center lg:w-1/6"
      key={product.name}
    >
      <img src={product.logo} alt={product.name} ref={props.imageRef} />
      <button
        className="my-8 mx-4 rounded-sm bg-[#efa14e] px-6 py-2 font-montserrat text-base font-bold uppercase text-mf-brown-200"
        onClick={() => handleOpen(product.name)}
      >
        Buy Now
      </button>
    </div>
  ));

  return (
    <section
      className="flex flex-col items-center bg-mf-orange-200 py-12 font-futura text-2xl text-white [&>p]:my-2"
      ref={ref}
      id="where-to-buy"
    >
      <div className="mx-auto h-16 w-48 bg-whiteLine bg-contain bg-center bg-no-repeat"></div>
      <p className="text-center font-gothamBold uppercase drop-shadow-[0_3px_0_#be3200] [&>span]:block">
        <span className="text-6xl">Where</span>
        <span className="mt-4 text-5xl text-mf-yellow-200">to buy</span>
      </p>
      <p className="max-lg:px-4 text-center font-alternate3 text-2xl lg:text-[30px] lg:leading-[42px] [&>span]:block">
        <span>We invite you to have all our products.</span>
        <span>Click each and find us in your favorite retailer.</span>
      </p>
      <div className="my-8 flex flex-col items-center justify-center gap-8 lg:flex-row">
        {productsList}
      </div>
    </section>
  );
});

export default WhereToBuy;
