import React, { useState } from "react";
import { Logo, cartIcon } from "../assets/images/";
import { SocialMediaNav } from "./";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faBars } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';

const SocialMedia = ({ WTBRef }) => {
  return (
    <div className="my-8 flex flex-col justify-end gap-8 sm:flex-row">
      <SocialMediaNav />
      <button
        className="-ml-4 mr-16 flex items-center justify-center gap-2"
        onClick={(e) => scrollTo(e, WTBRef)} id="alx-logo-isadora-home"
      >
        <img src={cartIcon} alt="" className="mr-2 w-10" />
        <p className="text-center text-2xl font-semibold uppercase text-white">
          Where to buy
        </p>
      </button>
    </div>
  );
};

const scrollTo = (e, ref) => {
  e.preventDefault();
  ref.current.scrollIntoView({ behavior: "smooth" });
};

const Header = ({
  contactRef,
  WTBRef,
  recipesRef,
  aboutUsRef,
  productsRef,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const sectionData = [
    { name: "About Us", ref: aboutUsRef },
    { name: "Products", ref: productsRef },
    { name: "Recipes", ref: recipesRef },
    { name: "Contact", ref: contactRef },
  ];
  const navElems = [];
  const navElemStyle = "text-lg lg:text-xl xl:text-2xl text-white uppercase";

  sectionData.map((elem) =>
    navElems.push(
      <button
        key={elem.name}
        onClick={(e) => scrollTo(e, elem.ref)}
        className={navElemStyle}
      >
        {elem.name}
      </button>
    )
  );

  return (
    <header className="flex flex-col justify-center bg-mf-orange-200 font-gothamBold relative z-50 drop-shadow-[0_10px_35px_rgba(251,119,0,1)]">
      <SocialMedia WTBRef={WTBRef} />
      <nav className="flex justify-between md:justify-center relative">
        <div className=" hidden px-4 text-center md:flex md:items-center md:gap-8 lg:gap-[6vw]">
          <Link to="/" id="alx-logo-isadora">
            <img
                src={Logo}
                alt="Isadora logo"
                className="w-52 lg:w-72 -mb-8 lg:-mb-16"
            />
          </Link>

          {navElems[0]}
          {navElems[1]}
          
          {navElems[2]}
          {navElems[3]}
          <Link
        className="text-lg lg:text-xl xl:text-2xl text-white uppercase"
        to="/news"
      >NEWS</Link>
        </div>
        <div
          className={`flex w-full justify-between p-4 md:hidden ${
            isOpen ? "items-start" : ""
          }`}
          id="mobile-menu"
        >
          <img src={Logo} alt="Isadora Logo" className="w-1/3 sm:w-1/4" />
          <div className={`p-2 sm:px-3 ${isOpen ? "flex flex-col alx-menu-mobile-align" : "hidden"}`}>
            {navElems}
            <Link
        className="text-lg lg:text-xl xl:text-2xl text-white uppercase"
        to="/news"
      >NEWS</Link>
          </div>
          <button
            onClick={() => setIsOpen(!isOpen)}
            type="button"
            aria-controls="mobile-menu"
            aria-expanded="false"
          >
            <span className="sr-only">Abrir menú</span>
            <FontAwesomeIcon
              icon={isOpen ? faXmark : faBars}
              className="text-2xl text-white"
            />
          </button>
        </div>
      </nav>
    </header>
  );
};

export default Header;
