import PropTypes from 'prop-types';
import styles from './styles';

const RecipeList = ({ title, color, list, basis, borderRight }) => {
  return (
    <div
      className={`${basis}relative mb-5${
        borderRight ? ' ' + styles.borderRight(borderRight) : ''
      }`}
      
    >
      <h5 className={`font-gothamBold text-lg uppercase ${color} mb-1`}>
        {title}
      </h5>
      <div className={`${list.length > 1 ? 'lg:flex lg:gap-x-6' : ''}`}>
        {list.map((ul, i) => (
          <ul key={i}>
            {ul.map((li, index) => (
              <li
                className='align-center flex font-alternate2 text-xl leading-tight 1.5xl:text-[22px]'
                key={index}
              >
                <span
                  className={`mr-1 ${color}`}
                  dangerouslySetInnerHTML={{
                    __html:
                      title === 'Ingredients' ? '&bull;' : `${index + 1}.`,
                  }}
                ></span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: li,
                  }}
                ></span>
              </li>
            ))}
          </ul>
        ))}
      </div>
    </div>
  );
};

RecipeList.defaultProps = {
  basis: '',
  borderRight: null,
};

RecipeList.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
};

export default RecipeList;
