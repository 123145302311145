import { forwardRef } from 'react';
import {
  MoleBowlPack,
  BarbacoaBowlPack,
  PorkBowlPack,
  IngredientsIcons,
} from '../assets/images';

const AboutUs = forwardRef((props, ref) => {
  return (
    <div
      className="relative overflow-x-hidden md:-mb-12 lg:-mt-[85px] lg:pt-[85px]"
      id="about-us-section-bookmark"
    >
      <section className="bg-mf-orange-200" ref={ref}>
        <div className="container mx-auto max-w-screen-xl px-5">
          <div className="flex flex-row justify-center">
            <div className="flex flex-col  justify-center pt-10 text-white md:pt-16 md:pb-10 lg:w-[55%] lg:pb-0">
              <div className="mx-auto lg:max-w-[550px]">
                <h2 className="mb-7 text-center font-gothamBold text-2xl uppercase drop-shadow-[2px_3px_0_#be3200] md:text-4xl">
                  <span className="custom-fs-4-2r the-auth-m font-futuraBold text-4xl md:text-6xl">
                    The authentic
                  </span>
                  <br></br>
                  <span id="imported-from">imported from </span>
                  <span
                    className="font-reyesBlack text-3xl md:text-5xl"
                    id="imp-mexico"
                  >
                    México
                  </span>
                  <p id="ready-to-eat">
                    ready to eat
                    <span className="text-mf-yellow-200"> food line</span>
                  </p>
                </h2>

                <p className="font-s-1-8 margin-x-2-mobile text-center font-alternate3 text-xl text-white md:text-3xl lg:text-justify">
                  We are proud to present to you Isadora
                  <small className="small-trademark font-futuraBold">
                    &#174;
                  </small>
                  ; our authentic line of
                  <span className="font-gothamBold"> MEXICAN CUISINE, </span>
                  made in México using the latest technologies, packaged and
                  retorted in flexible pouches that
                  <span className="font-gothamBold">
                    {' '}
                    DO NOT REQUIRE REFRIGERATION.{' '}
                  </span>
                  The result is unparalleled originally flavored products that
                  bring out the passion, creativity and zest for life reflected
                  by our heritage.
                </p>
              </div>
            </div>
            <div className="hidden lg:block lg:w-[45%]">
              <div className="absolute -mt-24 block min-h-[760px] w-[860px] bg-barbacoaBowl bg-contain bg-top bg-no-repeat"></div>
            </div>
          </div>
          <div className="flex flex-col justify-center pb-36">
            <div className="mx-auto h-16 w-48 bg-whiteLine bg-contain bg-center bg-no-repeat"></div>
            <h3 className="lineHeight-09 mt-5 mb-14 text-center font-gothamMedium text-3xl uppercase text-white drop-shadow-[2px_3px_0_#be3200] md:mb-20 md:text-6xl">
              <span className="font-futuraBold text-4xl md:text-6xl">
                Best Quality
              </span>
              <br></br>
              <span className="text-alt-yellow-new-100">Ingredients</span>
            </h3>
          </div>
        </div>
        <div className="bg-white">
          <div className="container mx-auto flex max-w-screen-xl flex-col justify-center px-5 md:mb-10 md:flex-row lg:justify-between">
            <div className="mb-20 -mt-36 md:mb-0">
              <img
                src={MoleBowlPack}
                alt="Isadora Mole Pouch"
                className="mx-auto -mb-12 w-auto"
              />
              <h4 className="text-center font-gothamBold text-3xl uppercase text-mf-violet-200 lg:text-4xl">
                &bull; Mole &bull;
              </h4>
            </div>
            <div className="mb-20 md:mb-0 md:-mt-36">
              <img
                src={BarbacoaBowlPack}
                alt="Isadora Mole Pouch"
                className="mx-auto -mb-12 w-auto"
              />
              <h4 className="text-center font-gothamBold text-3xl uppercase text-mf-red-100 lg:text-4xl">
                &bull; Barbacoa &bull;
              </h4>
            </div>
            <div className="mb-20 md:mb-0 md:-mt-36">
              <img
                src={PorkBowlPack}
                alt="Isadora Mole Pouch"
                className="mx-auto -mb-12 w-auto"
              />
              <h4 className="text-center font-gothamBold text-3xl uppercase text-mf-green-200 lg:text-4xl">
                &bull; Pork &bull;
              </h4>
            </div>
          </div>
        </div>
      </section>
      <div>
        <div className="container mx-auto hidden max-w-screen-xl px-5 md:flex">
          <img
            src={IngredientsIcons}
            alt="Our ingredients"
            className="mx-auto w-auto"
          />
        </div>
      </div>
    </div>
  );
});

export default AboutUs;
