import PropTypes from 'prop-types';
import styles from './styles';
import { RecipeMainTitle, RecipeSubTitle, RecipeList } from './';

const RecipeBarbacoaBeefTaco = ({ data }) => {
  return (
    <article className={`${styles.article} ${styles.borderBottom}`}>
      <div className='text-center'>
        <RecipeMainTitle headline={data.title} color={data.color} />
        <RecipeSubTitle headline={data.recipes[0].title} id={data.recipes[0].id} m='lg:mb-10' />
      </div>
      <div className='justify-start gap-8 lg:flex'>
        <img
          src={data.recipes[0].img}
          alt={data.recipes[0].alt + ' recipe'}
          className={`${styles.moleChickenTacoImg()}`}
        />
        <div className='lg:-translate-x-[30%] xl:flex-shrink-0 2xl:translate-x-0'>
          {['Ingredients', 'Directions'].map((title, i) => (
            <RecipeList
              title={title}
              color={data.color}
              list={data.recipes[0].recipe[0][title.toLowerCase()]}
              key={i}
            />
          ))}
        </div>
      </div>
    </article>
  );
};

RecipeBarbacoaBeefTaco.propTypes = {
  data: PropTypes.object.isRequired,
};

export default RecipeBarbacoaBeefTaco;
