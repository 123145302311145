import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { Logo } from '../../assets/images';
import useModalStore from './useModalStore';

const StoreModal = () => {
  const storeList = useModalStore((state) => state.storeList);
  const isOpen = useModalStore((state) => state.isModalOpen);
  const updateModalState = useModalStore((state) => state.updateModalState);

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => updateModalState(false)}
      >
        <div className="fixed inset-0 overflow-auto bg-white/40 backdrop-blur-[0.125rem]">
          <div className="flex h-full items-center justify-center p-8 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="max-h-[90vh] w-[95%] max-w-[50rem] transform overflow-y-auto overflow-x-hidden rounded-2xl bg-[#fcf6ea] shadow-xl transition-all">
                <div className="flex h-20 w-full flex-col bg-[#ec8316] p-8">
                  <button
                    className="absolute right-8 flex h-8 w-8 flex-col items-center justify-end rounded-full bg-white text-4xl font-bold text-[#ec8316]"
                    onClick={() => updateModalState(false)}
                  >
                    &times;{/** Close button */}
                  </button>
                  <img
                    src={Logo}
                    alt="Isadora logo"
                    className="relative -top-1 mx-auto w-[70%] max-w-[12.5rem]"
                  />
                </div>
                <div className="mx-auto mt-16 w-[95%] bg-inherit font-alternate3 text-2xl text-mf-brown-200 lg:mt-20 lg:text-[1.75rem]">
                  <p className="leading-none">
                    Select your favorite retailer and buy our products ONLINE
                    NOW!
                  </p>
                  <div className='relative mt-8 grid grid-cols-2 gap-0 bg-inherit after:absolute after:bottom-0 after:h-8 after:w-full after:bg-inherit after:content-[""] sm:grid-cols-4 '>
                    {storeList}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default StoreModal;
