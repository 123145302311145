import { useState } from 'react';
import data from './data';
import styles from './styles';
import {
  RecipeBarbacoaBeefTaco,
  RecipeBarbacoaBeefTortillaFlautas,
  RecipeMoleChickenTaco,
  RecipeMoleChickenSliders,
  RecipePorkInGreenSalsaTaco,
  RecipePorkInGreenSalsaOnATostada,
} from './';

const [barbacoa, mole, pork] = data;
const recipes = [
  { text: 'barbacoa', bgColor: 'orange rounded-l-xl' },
  { text: 'mole', bgColor: 'purple' },
  { text: 'pork', bgColor: 'green rounded-r-xl' },
];

const RecipesAllSection = () => {
  const [selectedRecipe, setSelectedRecipe] = useState('barbacoa');
  return (
    <section className={styles.section}>
      <div className={styles.recipesSelector}>
        {recipes.map((recipe, i) => (
          <button
            key={i}
            onClick={setSelectedRecipe.bind(null, recipe.text)}
            className={`${styles.recipesBtn} bg-recipes-colors-${
              recipe.bgColor
            } ${
              recipe.text === selectedRecipe
                ? 'bg-opacity-100'
                : 'bg-opacity-50'
            }`}
          >
            {recipe.text}
          </button>
        ))}
      </div>
      <div className={`${styles[selectedRecipe]} ${styles.carousel}`}>
        <div className='min-w-full'>
          <RecipeBarbacoaBeefTaco data={barbacoa} />
          <RecipeBarbacoaBeefTortillaFlautas data={barbacoa} />
        </div>
        <div className='min-w-full'>
          <RecipeMoleChickenTaco data={mole} />
          <RecipeMoleChickenSliders data={mole} />
        </div>
        <div className='min-w-full'>
          <RecipePorkInGreenSalsaTaco data={pork} />
          <RecipePorkInGreenSalsaOnATostada data={pork} />
        </div>
      </div>
    </section>
  );
};

export default RecipesAllSection;
